import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//会员列表
export const userList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/list",
  });

//会员列表导出
export const userListExcel = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/user/list`,
  });

//获取查询条件
export const getSearchType = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/auth/getType",
  });

//获取公会
export const unionList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/getUnion",
  });

//公会设置
export const setUnion = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/setUnion",
  });

//博主审核列表
export const bloggerList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/blogger/list",
  });

//博主身份信息修改
export const bloggerIdentity = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/blogger/identity",
  });

//博主审核操作
export const setBloggerStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/blogger/status",
  });

//用户认证审核列表
export const certifiedList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/certified/list",
  });

//用户审核操作
export const setCertifiedStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/certified/status",
  });

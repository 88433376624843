
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import { cloneDeep } from "lodash";
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { parseTime, countryNoComList } from "@/utils";
import { bloggerIdentity, bloggerList, setBloggerStatus } from "@/api/request/player";

//组件
@Component({
  name: "BloggerList",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading = false;
  private bloggerData: any = {};
  private countryList: any[] = [];

  //定义变量
  private listQuery: any = {
    //页码数据
    row: 20,
    page: 1,

    //临时数据
    time: [],
    userid_str: "",

    //上传数据
    status: "",
    country: "",
    is_privacy: 0,
    endformat: "",
    startformat: "",
    userid: undefined,
  };

  //博主类型列表
  private bloggerTypeList: any[] = [
    { type: 0, name: "不是私密博主" },
    { type: 1, name: "是私密博主" },
  ];

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //审核状态
  private auditStatusList: any = [
    { status: "", name: "所有审核状态" },
    { status: "0", name: "未处理" },
    { status: "1", name: "通过" },
    { status: "2", name: "取消认证" },
    { status: "3", name: "拒绝" },
  ];

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await bloggerList(this.getListQuery());

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //获取查询条件
  private getListQuery(): any {
    //数据拷贝
    const data: any = GFunc.deepClone(this.listQuery);

    //用户ID赋值
    data.userid = Number(data.userid_str);

    //时间赋值
    data.time = GFunc.checkarray(data.time);
    data.endformat = DTCls.getEndTimeFormat(data.time[1]);
    data.startformat = DTCls.getStartTimeFormat(data.time[0]);

    //返回数据
    return data;
  }

  //处理通过
  private handlePassBlogger(row: any): void {
    //数据赋值
    this.is_privacy = 0;
    this.passBloggerReason = "";
    this.bloggerData = cloneDeep(row);

    //显示界面
    this.passBloggerDialogVisible = true;
  }

  //博主身份
  private bloggerFormatter(row: any): any {
    if (row.blogger_identity === 1) return "自由博主";
    if (row.blogger_identity === 2) return "签约博主";
  }

  //时间数据
  private getTimeString(time: any): string {
    //数据赋值
    let str = parseTime(time);
    if (str === null) {
      str = "0000-00-00 00:00:00";
    }

    //返回结果
    return str;
  }

  //处理修改身份
  private handleEditBlogger(row: any): void {
    //数据赋值
    let now, tobe;
    row.blogger_identity === 1 ? (now = "自由博主") : (now = "签约博主");
    row.blogger_identity === 1 ? (tobe = "签约博主") : (tobe = "自由博主");

    //显示提示
    this.$confirm(`您确定将id为${row.id}(${now})的认证修改为${tobe}？`, "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求数据
        const res: any = await bloggerIdentity({ id: row.id, userid: row.userid, blogger_identity: row.blogger_identity === 1 ? 2 : 1 });

        //成功
        if (res.status == 0) {
          //显示提示
          this.$message.success("修改成功");

          //获取列表
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //获取审核状态名
  private getAuditStatusName(status: number): string {
    //数据赋值
    for (let idx = 1; idx < this.auditStatusList.length; idx++) {
      const data = this.auditStatusList[idx];
      if (GFunc.checkint(data.status) === status) {
        return data.name;
      }
    }

    //返回空
    return "--";
  }

  //拒绝
  private handleRejectBlogger(row: any, type: any): void {
    //数据赋值
    this.bloggerData = cloneDeep(row);

    //取消认证
    if (type === "cancel") {
      //显示提示
      this.$confirm(`您确定取消id为${row.id}的认证？`, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          //修改状态
          this.trySetBloggerStatus(row, 2, "", 0);

          //获取数据
          this.getList();
        })
        .catch((err) => {
          console.error(err);
        });
    }
    //拒接
    else {
      //数据赋值
      this.rejectBloggerDialogVisible = true;
      this.rejectBloggerReason = "";
    }
  }

  //处理博主操作
  private handleBloggerOp(row: any, status: any, reason: string, is_privacy: number): void {
    //提示信息
    const msg = status === 1 ? "您正在【通过】该博主提起的审核请求，您确定操作吗？" : "您正在【拒绝】该博主提起的审核请求，您确定操作吗？";

    //显示提示
    MessageBox.confirm(msg, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        //修改状态
        this.trySetBloggerStatus(row, status, reason, is_privacy);

        //获取数据
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //设置博主状态
  private async trySetBloggerStatus(row: any, status: any, reason: string, is_privacy: number) {
    //数据赋值
    const index = this.list.findIndex((v) => v.id === row.id);
    reason = reason === undefined || reason === null ? "" : reason;
    const reqParams = { id: row.id, status: status, memo: reason, is_privacy: is_privacy };

    //显示等待
    this.listLoading = true;

    //请求
    await setBloggerStatus(reqParams);

    //获取列表
    this.getList();

    //显示提示
    Message({
      message: "操作成功",
      type: "success",
      duration: 5 * 1000,
    });
  }

  //----------------------------------------- 拒绝原因 -----------------------------------------
  //定义变量
  private rejectBloggerReason: string = "";
  private rejectBloggerDialogVisible: boolean = false;

  //确定拒绝
  private confirmRejectBlogger() {
    //变更提现审核
    this.handleBloggerOp(this.bloggerData, 3, this.rejectBloggerReason, 0);

    //隐藏对话框
    this.rejectBloggerDialogVisible = false;
  }

  //----------------------------------------- 通过原因 -----------------------------------------
  //定义变量
  private is_privacy: number = 0;
  private passBloggerReason: string = "";
  private passBloggerDialogVisible: boolean = false;

  //确定通过
  private confirmPassBlogger() {
    //提交审核
    this.handleBloggerOp(this.bloggerData, 1, this.passBloggerReason, this.is_privacy);

    //隐藏对话框
    this.passBloggerDialogVisible = false;
  }
}
